export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: 'Excuses voor het ongemak',
  subheader: 'De door jou opgevraagde pagina is niet beschikbaar.',
  buttonText: 'Home',
}
